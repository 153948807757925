<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="container">
      <div class="row mb-3">
        <div class="col-4 col-md-3 ">
          <h4>{{ question.options.head.sector }}</h4>
        </div>
        <div class="col-4 col-md-3 ">
          <h4>{{ question.options.head.option1 }}</h4>
        </div>
        <div class="col-4 col-md-3 ">
          <h4>{{ question.options.head.option2 }}</h4>
        </div>
      </div>
      <div v-for="(continent, i) in question.options.values" :key="continent.order">
        <div class="my-4">
          <h5 v-show="i === 0" class="text-center text-md-start">{{ continent.title }}</h5>
          <div v-show="i === 1" class="divider"></div>
          <!--          <h5 v-show="i === 1" >{{ continent.name }}</h5>-->
        </div>
        <div class="row " v-for="country in continent.countries" :key="country.order">
          <div class="col-4 col-md-3 ">
            <p class="ps-md-5" v-show="i === 0">{{ country.name }}</p>
            <h5 v-show="i === 1" class="fw-bold">{{ country.name }}</h5>
            <div class="form-group mb-4 wet-form" v-show="i === 1">
              <!--              <label class="label">{{ question.options.alternative.text }}</label>-->
              <input type="text" class="form-control " placeholder="Pais">
            </div>
          </div>
          <div class="col-4 col-md-3 d-flex align-items-center">
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox"
                     @input="handleOptionCheckBox($event.target.checked, country, 'option1')"
                     :checked="result && result.some(x => x.option1 === true && x.name === country.name)" value=""
                     :id="`option1${country.name}`">
            </div>
          </div>
          <div class="col-4 col-md-3 d-flex align-items-center">
            <div class="form-check">
              <input class="form-check-input checkbox" type="checkbox"
                     @input="handleOptionCheckBox($event.target.checked, country, 'option2')"
                     :checked="result && result.some(x => x.option2 === true && x.name === country.name)" value=""
                     :id="`option2${country.name}`">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="row d-flex align-items-center mt-5  ps-md-5">-->
    <!--      <div class="col-4 col-md-3">-->
    <!--        <div class="form-group">-->
    <!--          <label class="label">{{ question.options.alternative.text }}</label>-->
    <!--          <input type="text" class="form-control wet-form" v-model="alternative.value">-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-4 col-md-3">-->
    <!--        <div class="form-check">-->
    <!--          <input class="form-check-input checkbox" type="checkbox" :checked="alternative.option1 === true" value="">-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-4 col-md-3">-->
    <!--        <div class="form-check">-->
    <!--          <input class="form-check-input checkbox" type="checkbox" :checked="alternative.option2 === true" value="">-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handleNext">
          {{ $store.getters.getNextText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {countriesInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Countries",
  components: {},
  props: {},
  data() {
    return {
      question: countriesInitialData,
      result: null,
      alternative: {},
    }
  },
  mounted() {
    this.result = this.question.result.answer.options;
    this.alternative = this.question.result.answer.alternative
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer.options;
        this.alternative = store.state.currentQuestion.result.answer.alternative;
      }
    }
  },
  methods: {
    handleOptionCheckBox(event, country, option) {
      let obj;
      if (option === 'option1') {
        obj = {
          option1: event,
          option2: country.option2,
          name: country.name,
          order: country.order,
        };
      } else {
        obj = {
          option2: event,
          option1: country.option1,
          name: country.name,
          order: country.order,
        };
      }
      if (this.result.some(x => x.name === country.name)) {
        if (option === 'option1') {
          const current = this.result.filter(x => x.name === country.name)[0]
          current.option1 = event;
          const index = this.result.findIndex(x => x.name === country.name);
          this.result.splice(index, 1, current);
        } else {
          const current = this.result.filter(x => x.name === country.name)[0]
          current.option2 = event;
          const index = this.result.findIndex(x => x.name === country.name);
          this.result.splice(index, 1, current);
        }
      } else {
        this.result.push(obj);
      }
    },
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: {
          options: this.result,
          alternative: this.alternative
        }
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
  },

}
</script>

<style scoped lang="scss">
.icon {
  fill: #23AF4D;
}

.divider {
  width: 100%;
  border: 1px solid #23AF4D;
}

.controls-button {
  &:hover {
    .icon {
      fill: white;
    }
  }
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.checkbox {
  border-color: #23af4d !important;

  &:checked {
    background-color: #23af4d !important;
  }
}
</style>
